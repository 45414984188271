import React, { ReactNode } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import 'typeface-roboto'

import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'

import { Box, Flex, Text } from '../vendor/voidkit/ui'

type LayoutProps = {
  children: ReactNode
}

type PureLayoutProps = {
  title: string
  children: ReactNode
}

const LayoutQuery = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export const Layout = ({ children }: LayoutProps) => (
  <StaticQuery query={LayoutQuery} render={data => <PureLayout title={data.site.siteMetadata.title} children={children} />} />
)

const Container = styled(Flex)`
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  background-color: #edf0f2;
`

const Header = styled(Box)``

Header.defaultProps = {
  as: 'header',
}

const Title = styled(Text)``

Title.defaultProps = {
  as: 'h1',
  m: 2,
}

const Main = styled(Flex)`
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow: auto;
`

const Footer = styled(Text)``

Footer.defaultProps = {
  as: 'footer',
  m: 2,
}

export const PureLayout = ({ title, children }: PureLayoutProps) => (
  <Container>
    <Global
      styles={css`
        html,
        body {
          margin: 0;
          width: 100vw;
          height: 100vh;
          font-family: 'Roboto', Georgia, Cambria, 'Times New Roman', Times, serif;
          overflow: hidden;
        }
      `}
    />
    <Header>
      <Title>{title}</Title>
    </Header>
    <Main>{children}</Main>
    <Footer>© 2018, Okkro</Footer>
  </Container>
)

export default Layout
