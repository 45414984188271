import styled from '@emotion/styled'
import React, { createRef } from 'react'

type TargetValue = {
  target: {
    value: string
  }
}

type ContentEditableProps = {
  value: string
  containerProps?: any
  onChange?(e: TargetValue): void
}

const Container = styled('div')`
  &:focus {
    outline: none;
  }
`

class ContentEditable extends React.Component<ContentEditableProps> {
  private ref = createRef<HTMLDivElement>()

  onKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case 'Enter':
        if (event.shiftKey) {
          event.preventDefault()
          this.emitChange()
        }
        break
      default:
      // Do nothing
    }
  }

  blur = () => {
    window.getSelection().removeAllRanges()
  }

  emitChange = () => {
    if (this.ref && this.ref.current) {
      const value = this.ref.current.innerHTML
      if (value !== this.props.value) {
        if (this.props.onChange) this.props.onChange({ target: { value } })
        this.blur()
      }
    }
  }

  render() {
    return (
      <Container
        {...this.props.containerProps}
        contentEditable
        ref={this.ref}
        dangerouslySetInnerHTML={{ __html: this.props.value }}
        onBlur={this.emitChange}
        onKeyDown={this.onKeyDown}
      />
    )
  }
}

export default ContentEditable
