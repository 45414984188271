import {
  // Common
  space,
  color,
  // Layout
  display,
  size,
  width,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  ratio,
  overflow,
  position,
  top,
  right,
  bottom,
  left,
  // Borders
  borders,
  borderColor,
  borderRadius,
  // Flexbox
  flex,
  order,
  // alignSelf,
  flexBasis,
  flexWrap,
  flexDirection,
  alignItems,
  justifyContent,
  alignContent,
  justifyItems,
  // Typography
  fontFamily,
  fontSize,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  // buttonStyle,
  // boxShadow,
  // backgroundImage,
  // backgroundSize,
  // backgroundPosition,
  // backgroundRepeat,
  // opacity,
  // variant,
  // Util
  compose,
} from 'styled-system'

export const common = compose(
  color,
  space,
)

export const border = compose(
  borders,
  borderColor,
  borderRadius,
)

export const typography = compose(
  common,
  fontFamily,
  fontSize,
  fontWeight,
  // FIXME: fontStyle?
  textAlign,
  lineHeight,
  letterSpacing,
)

export const layout = compose(
  common,
  border,
  display,
  size,
  width,
  maxWidth,
  minWidth,
  height,
  maxHeight,
  minHeight,
  ratio,
  flex,
  overflow,
  // FIXME: verticalAlign?
  position,
  top,
  right,
  bottom,
  left,
)

export const flexbox = compose(
  layout,
  flexBasis,
  flexDirection,
  flexWrap,
  alignItems,
  alignContent,
  justifyItems,
  justifyContent,
  // FIXME: justifySelf? => FLEX_ITEM
  // FIXME: alignSelf?
  order,
)

// FIXME
// export const GRID = [
//   ...layout,
//   gridGap,
//   gridColumnGap,
//   gridRowGap,
//   gridColumn,
//   gridRow,
//   gridAutoFlow,
//   gridAutoColumns,
//   gridAutoRows,
//   gridTemplateColumns,
//   gridTemplateRows,
//   gridTemplateAreas,
//   gridArea,
// ]

// const all = compose(
//   common,
//   border,
//   typography,
//   layout,
//   position,
//   flexbox,
// )

// export const styled = tag => {
//   const allKeys = Object.keys(all.propTypes)
//   const shouldForwardProp = prop => isPropValid(prop) && !allKeys.includes(prop)
//   return styledAlias(tag, { shouldForwardProp })
// }

export { default as styled } from '@emotion/styled'

export { css } from '@emotion/core'
