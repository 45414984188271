import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

type SEOProps = {
  title: string
  description?: string
  lang?: string
  meta?: any[]
  keywords?: string[]
}

type PureSEOProps = {
  title: string
  titleTemplate: string
  description: string
  lang: string
  author: string
  meta: any[]
}

const SEOQuery = graphql`
  query SEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`

export const SEO = ({ title, description, lang, meta, keywords }: SEOProps) => (
  <StaticQuery
    query={SEOQuery}
    render={data => (
      <PureSEO
        title={title}
        titleTemplate={`%s | ${data.site.siteMetadata.title}`}
        description={description || data.site.siteMetadata.description}
        lang={lang || 'en'}
        author={data.site.siteMetadata.author}
        meta={(meta || []).concat(keywords && keywords.length > 0 ? { name: `keywords`, content: keywords.join(`, `) } : [])}
      />
    )}
  />
)

export const PureSEO = ({ title, titleTemplate, description, lang, author, meta }: PureSEOProps) => (
  <Helmet
    htmlAttributes={{ lang }}
    title={title}
    titleTemplate={titleTemplate}
    meta={[
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: description,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: author,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: description,
      },
    ].concat(meta)}
  />
)

export default SEO
