import styled from '@emotion/styled'
import { layout, flexbox } from './system-props'
import Box from './box'

const Flex = styled(Box)`
  display: flex;
  ${layout}
  ${flexbox}
`

export default Flex
