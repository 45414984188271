import React from 'react'

import styled from '@emotion/styled'
import Box from './box'

type GridProps = {
  pan: { x: number; y: number }
  scale?: number
  blockSize?: number
  blocks?: number
}

const SVG = styled(Box)`
  position: absolute;
  height: ${props => props.size.height * 3}px;
  width: ${props => props.size.width * 3}px;
  transform: translate(
    ${props =>
      `${-props.size.width + (props.pan.x % props.size.bigBlock)}px, ${-props.size.height + (props.pan.y % props.size.bigBlock)}px`}
  );
`

SVG.defaultProps = {
  as: 'svg',
}

const Grid = ({ pan, scale = 1, blockSize = 8, blocks = 10 }: GridProps) => {
  const block = blockSize * scale
  const bigBlockSize = blockSize * blocks * scale
  let vh = 0
  let vw = 0
  let rvh = 0
  let rvw = 0
  if (typeof window !== 'undefined') {
    vh = window.innerHeight
    vw = window.innerWidth
    rvh = vh + (bigBlockSize - (vh % bigBlockSize))
    rvw = vw + (bigBlockSize - (vw % bigBlockSize))
  }
  return (
    <SVG
      width="100%"
      height="100%"
      xmlns="http://www.w3.org/2000/svg"
      pan={{ x: pan.x, y: pan.y }}
      size={{ block, width: rvw, height: rvh, bigBlock: bigBlockSize }}>
      <defs>
        <pattern id="smallGrid" width={block} height={block} patternUnits="userSpaceOnUse">
          <path d={`M ${block} 0 h -${block} v ${block}`} fill="none" stroke="gray" strokeWidth="0.5" />
        </pattern>
        <pattern id="grid" width={bigBlockSize} height={bigBlockSize} patternUnits="userSpaceOnUse">
          <rect width={bigBlockSize} height={bigBlockSize} fill="url(#smallGrid)" />
          <path d={`M ${bigBlockSize} 0 h -${bigBlockSize} v ${bigBlockSize}`} fill="none" stroke="gray" strokeWidth="1" />
        </pattern>
      </defs>

      <rect width="100%" height="100%" fill="url(#grid)" />
    </SVG>
  )
}

export default Grid
